<template>
  <mobile-screen
    v-if="pageOptions"
    :header="true"
    screen-class="icon-app1 occupancy-screen reports-screen gray-bg"
  >
    <template v-slot:header>
      <top-header-menu-wrapper
        menu-class="occupancy-header icon-hea1"
        :helpOnline="
          Boolean(
            helpOnline[helpSlug] &&
              helpOnline[helpSlug].name &&
              helpOnline[helpSlug].content
          )
        "
        :helpOnlineRoute="{
          name: 'r_occupancy-help-online-historical-occupancy'
        }"
      >
        <template v-slot:left>
          <home-button />
        </template>
        <div class="component-title">
          {{ displayLabelName("occupancy.header.historical-occupancy") }}
        </div>
        <template v-slot:right>
          <button @click="toggleSearch">
            <icon icon="#cx-hea1-search" />
          </button>
        </template>
      </top-header-menu-wrapper>
      <search
        v-if="search"
        @changedInput="modifySearchQuery"
        @clear="clearSearchQuery"
        @startSearch="submitSearch"
        :hasFilters="true"
        :filters="filters"
        :routeName="pageOptions.searchRouteName"
      ></search>
    </template>
    <div v-if="filtersChanged" class="filters-changed-overlay"></div>
    <ul class="clebex-item-section calendar">
      <li class="clebex-item-section-item">
        <div class="clebex-item-content-wrapper">
          <ul class="clebex-item-content-range">
            <li class="clebex-item-range from">
              <button
                class="clebex-item-range-cta"
                @click.prevent="openDateTimePicker('date')"
              >
                {{ displayDate(selectedDateFromParsed) }}
              </button>
            </li>
            <li class="clebex-item-range separator">
              <icon icon="#cx-app1-calendar" width="16" height="16" />
            </li>
            <li class="clebex-item-range to">
              <button
                @click.prevent="openDateTimePicker('date')"
                class="clebex-item-range-cta"
              >
                {{ displayDate(selectedDateToParsed) }}
              </button>
            </li>
          </ul>
        </div>
      </li>
      <li
        class="clebex-item-section-item overflow-hidden calendar-expanded days"
      >
        <ul class="checkbox-day-list" v-if="dayLabels">
          <li
            class="checkbox-wrapper"
            v-for="(dayLabel, i) in dayLabels"
            :key="i"
          >
            <div class="checkbox tiny alt block">
              <input
                type="checkbox"
                :id="`day${i}`"
                :name="dayLabel"
                v-model="days[i]"
              />
              <label :for="`day${i}`" class="day-of-the-week">
                <icon icon="#cx-app1-checkmark" />
                {{ dayLabel }}
              </label>
            </div>
          </li>
        </ul>
      </li>
      <li class="clebex-item-section-item time">
        <div class="clebex-item-content-wrapper">
          <ul class="clebex-item-content-range">
            <li class="clebex-item-range from">
              <button
                v-if="globalTimeFormat"
                class="clebex-item-range-cta"
                @click.prevent="openDateTimePicker('time-slot')"
              >
                {{ displayTime(selectedHourFrom) }}
              </button>
            </li>
            <li class="clebex-item-range separator">
              <icon icon="#cx-app1-time" width="16" height="16" />
            </li>
            <li class="clebex-item-range to">
              <button
                v-if="globalTimeFormat"
                @click.prevent="openDateTimePicker('time-slot')"
                class="clebex-item-range-cta"
              >
                {{ displayTime(selectedHourTo) }}
              </button>
            </li>
          </ul>
        </div>
      </li>
    </ul>
    <ul class="clebex-item-section calendar calendar-picker">
      <li
        class="clebex-item-section-item overflow-hidden calendar-expanded"
        v-if="showDateTimePicker"
      >
        <div class="clebex-item-content-wrapper">
          <pill-menu-tabs
            :nav-items="pillTabs"
            :preselected-tab="preselectedTab"
          >
            <template v-slot:date>
              <select-date-range
                :selectDateFn="selectDate"
                :show-date-picker="showDateTimePicker"
                :dates-to-watch="[selectedDateFrom, selectedDateTo]"
                :selected-dates="[selectedDateFrom, selectedDateTo]"
              />
            </template>
            <template v-slot:time-slot>
              <select-time-picker-range
                :select-hour-fn="selectHour"
                :selected-hours="[selectedHourFrom, selectedHourTo]"
              />
            </template>
          </pill-menu-tabs>
        </div>
      </li>
    </ul>
    <ul
      v-if="filtersChanged"
      class="clebex-item-section search-refresh"
      :class="{ 'calendar-expanded': showDateTimePicker }"
    >
      <li class="clebex-item-section-item no-bottom-border">
        <div class="clebex-item-content-wrapper">
          <div class="clebex-section-input">
            <label class="clebex-section-input-label">{{
              displayLabelName("occupancy", "reports", "refresh")
            }}</label>
          </div>
          <span @click="getData" class="follow-up-icon-item">
            <icon icon="#cx-foo1-refresh-28x28" />
          </span>
        </div>
      </li>
    </ul>
    <div
      v-if="chartData"
      class="chart-wrapper"
      :class="{
        'is-mobile': isMobileDevice(),
        'is-windows': isWindowsDevice()
      }"
    >
      <div
        class="chart-wrapper"
        :style="{
          width: chartWidth
        }"
      >
        <Line
          :chart-options="pageOptions.chartOptionsY"
          :chart-data="chartData"
          chart-id="line-chart"
          dataset-id-key="label"
          css-classes="chart-canvas"
        />
      </div>
    </div>
    <template v-slot:footer>
      <occupancy-footer-menu></occupancy-footer-menu>
    </template>
  </mobile-screen>
  <router-view />
</template>

<script>
import { defineAsyncComponent } from "vue";
import MobileScreen from "@/layouts/MobileScreen";
import TopHeaderMenuWrapper from "@/components/global/TopHeaderMenuWrapper";
import { DateTime, Interval } from "luxon";
import { formatDate, rgbToHex } from "@/services/helpers";
import { mapState, mapGetters, mapActions } from "vuex";
import OccupancyFooterMenu from "@/components/occupancy/OccupancyFooterMenu";
import { getLang } from "@/services/http-service";
import { Line } from "vue-chartjs";
import helpOnlineMixin from "@/services/mixins/help_online/help-online-mixin";
import {
  Chart as ChartJS,
  Title,
  Tooltip,
  LineElement,
  LinearScale,
  CategoryScale,
  PointElement
} from "chart.js";
ChartJS.register(
  Title,
  Tooltip,
  LineElement,
  CategoryScale,
  LinearScale,
  PointElement
);
export default {
  name: "HistoricalOccupancy",
  mixins: [helpOnlineMixin],
  components: {
    MobileScreen,
    TopHeaderMenuWrapper,
    OccupancyFooterMenu,
    SelectDateRange: defineAsyncComponent(() =>
      import("@/components/global/SelectDateRange")
    ),
    SelectTimePickerRange: defineAsyncComponent(() =>
      import("@/components/global/SelectTimePickerRange")
    ),
    PillMenuTabs: defineAsyncComponent(() =>
      import("@/components/global/PillMenuTabs")
    ),
    Search: defineAsyncComponent(() => import("@/components/search/Search")),
    Line
  },
  data() {
    return {
      selectedDateFrom: false,
      selectedDateTo: false,
      selectedHourFrom: null,
      selectedHourTo: null,
      selectedDateFromParsed: null,
      selectedDateToParsed: null,
      search: false,
      calendarExpanded: false,
      showLegend: true,
      pillTabs: ["date", "time-slot"],
      preselectedTab: null,
      showDateTimePicker: false,
      days: [true, true, true, true, true, false, false],
      dayLabels: null,
      pageOptions: {
        pageTitle: "historical-occupancy",
        searchRouteName: "r_occupancy-historical-occupancy-search-filters",
        filtersSection: "occupancy_historical",
        barWidth: 100,
        chartOptionsY: {
          responsive: true,
          maintainAspectRatio: false
        }
      },
      helpSlug: "occupancy-historical"
    };
  },
  created() {
    if (this.$route.matched.length > 1) {
      this.search = true;
    }
    this.setInitialValues();
    this.$store.commit("global/setIsFullWidthScreen", true, {
      root: true
    });
  },
  computed: {
    ...mapGetters("settings", ["globalDateFormat", "globalTimeFormat"]),
    ...mapState("searchFilters", [
      "filters",
      "filtersChanged",
      "filterPresets",
      "filtersClosed"
    ]),
    ...mapState("occupancy", ["occupancyData"]),
    chartWidth() {
      if (
        this.occupancyData &&
        this.occupancyData.data &&
        this.occupancyData.data.length
      ) {
        return `${100 +
          this.occupancyData.data.length * this.pageOptions.barWidth}px`;
      } else {
        return "1000px";
      }
    },
    selectedDateTimeFilter() {
      if (
        this.filters &&
        this.filters.length &&
        this.filters.find(filter => filter.slug === "dates" && filter.selected)
      ) {
        return this.filters.find(
          filter => filter.slug === "dates" && filter.selected
        );
      } else {
        return null;
      }
    },
    chartData() {
      let chartData = { labels: [], datasets: [] };
      if (
        this.occupancyData &&
        this.occupancyData.data &&
        this.occupancyData.data.length
      ) {
        let values = [];
        chartData.labels = this.occupancyData.data.map(e => e.date);
        values = this.occupancyData.data.map(e => e.busy);
        const color =
          this.occupancyData.color.indexOf("#") < 0
            ? rgbToHex(this.occupancyData.color)
            : this.occupancyData.color;

        chartData.datasets = [
          {
            data: values,
            backgroundColor: color,
            borderColor: `${color}80`,
            hoverBackgroundColor: color,
            pointBackgroundColor: color,
            lineTension: 0.5
          }
        ];
      }
      return chartData;
    }
  },
  methods: {
    ...mapActions("occupancy", [
      "getOccupancyFilters",
      "calculate"
      // "getHistoricalOccupancyData",
    ]),
    ...mapActions("searchFilters", ["selectFilter", "resetFilters"]),
    modifySearchQuery(searchQuery) {
      this.$store.commit("search/setSearchQuery", searchQuery, {
        root: true
      });
    },
    clearSearchQuery() {
      this.$store.commit("search/setSearchQuery", "", {
        root: true
      });
      this.submitSearch();
    },
    submitSearch() {
      this.calculate({ section: this.pageOptions.filtersSection });
    },
    selectDate(dates) {
      if (dates[0]) {
        this.selectedDateFrom = DateTime.fromISO(dates[0].eventDate).toFormat(
          "yyyy-dd-MM"
        );
        this.selectedDateFromParsed = dates[0].displayDate;
      } else {
        this.selectedDateFrom = null;
        this.selectedDateFromParsed = null;
      }
      if (dates[1]) {
        this.selectedDateTo = DateTime.fromISO(dates[1].eventDate).toFormat(
          "yyyy-dd-MM"
        );
        this.selectedDateToParsed = dates[1].displayDate;
        this.calendarExpanded = false;
      } else {
        this.selectedDateTo = null;
        this.selectedDateToParsed = null;
      }
      if (dates[0] && dates[1]) {
        this.setCustomDate(dates, false);
      }
    },
    selectHour(hours) {
      this.selectedHourFrom = hours[0];
      this.selectedHourTo = hours[1];
      if (hours[0] && hours[1]) {
        this.setCustomDate(false, hours);
      }
    },
    setCustomDate(dates, hours) {
      let customDateTimeFilter = this.filters.find(
        filter => filter.slug === "dates"
      );
      const defaultStart = this.selectedDateTimeFilter.date_start;
      const defaultEnd = this.selectedDateTimeFilter.date_end;
      if (!hours) {
        customDateTimeFilter.date_start = dates[0]
          ? `${DateTime.fromISO(
              dates[0].eventDate
            ).toISODate()}T${DateTime.fromISO(defaultStart).toFormat("HH:mm")}`
          : defaultStart;
        customDateTimeFilter.date_end = dates[1]
          ? `${DateTime.fromISO(
              dates[1].eventDate
            ).toISODate()}T${DateTime.fromISO(defaultEnd).toFormat("HH:mm")}`
          : defaultEnd;
      }
      if (!dates) {
        customDateTimeFilter.date_start = hours[0]
          ? `${DateTime.fromISO(defaultStart).toISODate()}T${DateTime.fromISO(
              hours[0]
            ).toFormat("HH:mm")}`
          : defaultStart;
        customDateTimeFilter.date_end = hours[1]
          ? `${DateTime.fromISO(defaultEnd).toISODate()}T${DateTime.fromISO(
              hours[1]
            ).toFormat("HH:mm")}`
          : defaultEnd;
      }
      const start = DateTime.fromISO(customDateTimeFilter.date_start);
      const end = DateTime.fromISO(customDateTimeFilter.date_end);
      customDateTimeFilter.hoursInterval = Interval.fromDateTimes(
        start,
        end
      ).length("hours");
      customDateTimeFilter.selected = false;
      this.selectFilter({ filter: customDateTimeFilter, selected: true });
    },
    setInitialValues() {
      this.modifySearchQuery("");
      this.setDayLabels();
      this.getOccupancyFilters(this.pageOptions.filtersSection).then(() => {
        this.setInitialDateTime();
      });

      this.calculate({
        section: this.pageOptions.filtersSection,
        initial: true
      });
    },
    setInitialDateTime() {
      const initialDateTimeFilter = this.filterPresets
        .find(preset => preset.selected)
        .filters.find(filter => filter.slug === "dates");
      this.setDateTime(initialDateTimeFilter);
    },
    setDateTime(filter) {
      this.selectedDateFrom = DateTime.fromISO(filter.date_start).toFormat(
        "yyyy-dd-MM"
      );
      this.selectedDateTo = DateTime.fromISO(filter.date_end).toFormat(
        "yyyy-dd-MM"
      );
      this.selectedDateFromParsed = DateTime.fromISO(
        filter.date_start
      ).toFormat("d MMMM yyyy");
      this.selectedDateToParsed = DateTime.fromISO(filter.date_end).toFormat(
        "d MMMM yyyy"
      );
      this.selectedHourFrom = DateTime.fromISO(filter.date_start).toFormat(
        "HH:mm"
      );
      this.selectedHourTo = DateTime.fromISO(filter.date_end).toFormat("HH:mm");
    },
    displayDate(date) {
      if (!date) {
        return this.displayLabelName("global", "calendar", "select-date");
      }
      return formatDate(date, this.globalDateFormat);
    },
    displayTime(time) {
      if (!time) {
        return this.displayLabelName("global", "calendar", "time-slot");
      }
      // Luxon fix for am/pm... remove when globalDateFormat is Luxon oriented
      let timeFormat = this.globalTimeFormat;
      if (timeFormat.slice(-1) === "A") {
        timeFormat = timeFormat.replace(/.$/, "a");
      }
      return DateTime.fromISO(time).toFormat(timeFormat || "HH:mm");
    },
    openDateTimePicker(tab) {
      if (this.preselectedTab !== tab) {
        this.preselectedTab = tab;
        this.showDateTimePicker = true;
      } else {
        this.showDateTimePicker = !this.showDateTimePicker;
      }
    },
    setDayLabels() {
      const locale = getLang();
      this.dayLabels = [
        DateTime.now()
          .set({ weekday: 1 })
          .toFormat("ccc", { locale: locale }),
        DateTime.now()
          .set({ weekday: 2 })
          .toFormat("ccc", { locale: locale }),
        DateTime.now()
          .set({ weekday: 3 })
          .toFormat("ccc", { locale: locale }),
        DateTime.now()
          .set({ weekday: 4 })
          .toFormat("ccc", { locale: locale }),
        DateTime.now()
          .set({ weekday: 5 })
          .toFormat("ccc", { locale: locale }),
        DateTime.now()
          .set({ weekday: 6 })
          .toFormat("ccc", { locale: locale }),
        DateTime.now()
          .set({ weekday: 7 })
          .toFormat("ccc", { locale: locale })
      ];
      this.setWeekdays();
    },
    setWeekdays() {
      let weekdays = [];
      this.days.forEach((day, i) => {
        if (day) {
          weekdays.push(
            DateTime.now()
              .set({ weekday: i + 1 })
              .toFormat("cccc")
              .toLowerCase()
          );
        }
      });
      this.$store.commit("occupancy/setWeekdays", weekdays, {
        root: true
      });
    },
    toggleSearch() {
      this.search = !this.search;
    },
    getData() {
      this.calculate({
        section: this.pageOptions.filtersSection
      });
      this.showDateTimePicker = false;
      this.$store.commit("searchFilters/setFiltersChanged", false, {
        root: true
      });
    }
  },
  beforeUnmount() {
    this.resetFilters();
    this.$store.commit("global/setIsFullWidthScreen", false, {
      root: true
    });
  },
  watch: {
    selectedDateTimeFilter: {
      handler(val) {
        if (val) {
          this.setDateTime(val);
        }
      },
      immediate: true
    },
    days: {
      handler() {
        this.$store.commit("searchFilters/setFiltersChanged", true, {
          root: true
        });
        this.setWeekdays();
      },
      deep: true
    },
    filtersClosed: {
      handler(val) {
        if (val) {
          if (this.filtersChanged) {
            this.calculate({ section: this.pageOptions.filtersSection });
            this.$store.commit("searchFilters/setFiltersChanged", false, {
              root: true
            });
          }
          this.$store.commit("searchFilters/setFiltersClosed", false, {
            root: true
          });
        }
      },
      immediate: true
    }
  }
};
</script>
